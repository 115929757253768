.layout-multiple-columns
  #mastodon
  .columns-area
  .status__action-bar
  :not(i, .status__action-bar-spacer) {
  max-width: unset !important;
}

.layout-single-column .columns-area__panels__main {
  contain: inline-size style !important;
}

body.app-body.flavour-glitch > #mastodon .notification__message > span,
.notification__message > span > span,
.status__prepend > span,
.status__prepend > span > span {
  display: unset !important;
  flex-wrap: unset !important;
  gap: unset !important;
}

body.app-body.flavour-glitch > #mastodon .reactions-bar button:not(.active) {
  background: color.adjust($ui-base-color, $lightness: 12%);
}

#mastodon .compose-form > .navigation-bar {
  margin-top: 10px !important;
}

#mastodon .compose-form {
  gap: 10px !important;
}

body.app-body.flavour-glitch
  > #mastodon
  .reactions-bar
  .emoji-picker-dropdown
  button {
  margin-left: 5px;
  padding: 6px;
}

.announcements .announcements__container .announcements__pagination {
  bottom: 0 !important;
}

@media (width <= 889px) {
  @supports selector(.foxxo:has(.waf)) {
    .columns-area__panels__pane--navigational a svg {
      height: unset !important;
      width: unset !important;
    }
  }
}

body.app-body.flavour-glitch
  > #mastodon
  .status__content.status__content--collapsed
  p:not(:last-child) {
  margin-top: 0.4em;
}

body.app-body.flavour-glitch > #mastodon .compose-form__submit {
  position: unset !important;
  margin-top: unset !important;
}

@media (width <= 889px) {
  .tabs-bar__wrapper {
    top: -1px !important;
    z-index: 3 !important;
  }
}

body.app-body.flavour-glitch > #mastodon .notification-group,
body.app-body.flavour-glitch > #mastodon .notification-ungrouped {
  border-bottom: 1px solid var(--background-border-color) !important;
}

.status__action-bar {
  margin-bottom: unset !important;

  .emoji-picker-dropdown {
    width: 100% !important;
  }
}

@media (width <= 889px) {
  .ui__header [href='/publish']::before {
    font: normal normal normal 14px / 1 sans-serif !important;
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" height="24" width="24" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="whitesmoke" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152L0 424c0 48.6 39.4 88 88 88l272 0c48.6 0 88-39.4 88-88l0-112c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 22.1-17.9 40-40 40L88 464c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 64z"/></svg>') !important;
  }
}

@media (width <= 889px) {
  .navigation-panel__menu {
    display: flex;
  }
}

.notification-request {
  margin-top: 60px;
}

.status__content.status__content--collapsed {
  .status__content__text {
    overflow: hidden;
    mask: linear-gradient(to bottom, #000 65%, transparent);
  }

  .status__content__translate-button,
  .status-card,
  .video-player {
    display: none;
  }
}

@media (width <= 889px) {
  .ui__header .ui__header__links {
    right: unset !important;
    inset-inline-end: unset !important;
  }
}
