.announcements__container {
  width: 100% !important;
}

.status__content--zoom-emojis-on-hover .emojione:hover {
  transform: scale(1.8);
  filter: drop-shadow(0 0 3px #0c0c0c);
  z-index: 99;
}

.icon-badge-wrapper {
  height: 24px;
  width: 24px;
}

.status__content:not(.status__content--collapsed) {
  overflow: visible;
}
