.poll {
  margin-top: 16px;
  font-size: 14px;

  ul,
  .e-content & ul {
    margin: 0;
    list-style: none;
  }

  li {
    margin-bottom: 10px;
    position: relative;
  }

  &__chart {
    border-radius: 4px;
    display: block;
    background: color.adjust($ui-primary-color, $lightness: -5%);
    height: 5px;
    min-width: 1%;

    &.leading {
      background: $ui-highlight-color;
    }
  }

  progress {
    border: 0;
    display: block;
    width: 100%;
    height: 5px;
    appearance: none;
    background: transparent;

    &::-webkit-progress-bar {
      background: transparent;
    }

    // Those rules need to be entirely separate or they won't work, hence the
    // duplication
    &::-moz-progress-bar {
      border-radius: 4px;
      background: color.adjust($ui-primary-color, $lightness: -5%);
    }

    &::-webkit-progress-value {
      border-radius: 4px;
      background: color.adjust($ui-primary-color, $lightness: -5%);
    }
  }

  &__option {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 6px 0;
    line-height: 18px;
    cursor: default;
    overflow: hidden;

    &__text {
      display: inline-block;
      word-wrap: break-word;
      overflow-wrap: break-word;
      max-width: calc(100% - 45px - 25px);
    }

    input[type='radio'],
    input[type='checkbox'] {
      display: none;
    }

    .autosuggest-input {
      flex: 1 1 auto;
    }

    input[type='text'] {
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-size: 14px;
      color: $secondary-text-color;
      outline: 0;
      font-family: inherit;
      background: $ui-base-color;
      border: 1px solid $darker-text-color;
      border-radius: 4px;
      padding: 8px 12px;

      &:focus {
        border-color: $ui-highlight-color;
      }

      @media screen and (width <= 600px) {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    &.selectable {
      cursor: pointer;
    }

    &.editable {
      align-items: center;
      overflow: visible;
    }
  }

  &__input {
    display: block;
    position: relative;
    border: 1px solid $ui-primary-color;
    box-sizing: border-box;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    flex: 0 0 auto;

    &.checkbox {
      border-radius: 4px;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: color.adjust($valid-value-color, $lightness: 15%);
      border-width: 4px;
    }

    &.active {
      background-color: $valid-value-color;
      border-color: $valid-value-color;
    }

    &::-moz-focus-inner {
      outline: 0 !important;
      border: 0;
    }

    &:focus,
    &:active {
      outline: 0 !important;
    }

    &.disabled {
      border-color: $dark-text-color;

      &.active {
        background: $dark-text-color;
      }

      &:active,
      &:focus,
      &:hover {
        border-color: $dark-text-color;
        border-width: 1px;
      }
    }
  }

  &__option.editable &__input {
    &:active,
    &:focus,
    &:hover {
      border-color: $ui-primary-color;
      border-width: 1px;
    }
  }

  &__number {
    display: inline-block;
    width: 45px;
    font-weight: 700;
    flex: 0 0 45px;
  }

  &__voted {
    padding: 0 5px;
    display: inline-block;

    &__mark {
      font-size: 18px;
    }
  }

  &__footer {
    padding-top: 6px;
    padding-bottom: 5px;
    color: $dark-text-color;
  }

  &__link {
    display: inline;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    color: $dark-text-color;
    text-decoration: underline;
    font-size: inherit;

    &:hover {
      text-decoration: none;
    }

    &:active,
    &:focus {
      background-color: rgba($dark-text-color, 0.1);
    }
  }

  .button {
    height: 36px;
    padding: 0 16px;
    margin-inline-end: 10px;
    font-size: 14px;
  }
}

.muted .poll {
  color: $dark-text-color;

  &__chart {
    background: rgba(color.adjust($ui-primary-color, $lightness: -14%), 0.7);

    &.leading {
      background: rgba($ui-highlight-color, 0.5);
    }
  }
}
